<script>
import pagesJson from '../pagelinks.js'

export default {
  name: "NavigationMenu",
  data() {
    return {
      items: pagesJson,
      scrolled: 0,
      headerHeight: 64 // 4 rem
    }
  },
  mounted() {
    this.headerHeight = document.getElementById("applicationHeader").offsetHeight
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      this.scrolled = window.scrollY
    }
  },
  computed: {
    overflowStyle() {
      return `--negative-height: ${this.scrolled < this.headerHeight ? this.headerHeight - this.scrolled : 0}px;`
    }
  }
}
</script>

<template>
  <div class="menu-container">
    <div class="menu-wrapper" :style="overflowStyle">
      <v-card
        height="100vh"
        width="max-content"
      >
        <v-navigation-drawer
          permanent
          expand-on-hover
        >
          <v-list
            dense
            nav
          >
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.to"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.menu-wrapper {
  width: min-content;
  position: sticky;
  top: 0;
  max-height: calc(100vh - var(--negative-height));
  overflow-y: hidden;
}

.menu-container {
  width: 56px;
  overflow: show;
  position: relative;
  z-index: 9990;
}
</style>