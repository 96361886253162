<template>
  <v-app>
    <div v-if="didCheck === false" class="loading-container">
      <div class="loading-wrapper">
        <v-progress-circular
          indeterminate
          color="green"
          size="100"
          width="6"
        />
      </div>
    </div>

    <router-view v-if="didCheck && $route.name === 'login'" />

    <template v-else-if="isLoggedIn">
      <TheHeader />

      <div class="main-container">
        <NavigationMenu />

        <div class="app-container">
          <v-btn
            class="mx-2"
            dark
            fab
            color="var(--primary-color)"
            v-on:click="onBackClick"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <router-view />
        </div>
      </div>
    </template>
  </v-app>
</template>

<script>
import TheHeader from './components/Header.vue'
import NavigationMenu from './components/navigationMenu.vue'
import router from './router'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    TheHeader,
    NavigationMenu
  },
  data() {
    return {
      isLoggedIn: false,
      didCheck: false
    }
  },
  created() {
    // used to set the login state after the user has logged in
    // also runs when the page is opened with a pathname
    this.$watch(() => this.$route.name, () => {
      if (!this.isLoggedIn) this.checkLogin()
    })
  },
  mounted() {
    // the $route.name watch is triggered when a route is given
    // so only run this function when there is no route
    if (window.location.pathname === "/") this.checkLogin()
  },
  methods: {
    async checkLogin() {
      // to make sure the user always looks at least 1 second to loading screen
      // improves experience because the screen does not immediately flash away
      let doneWaiting = false
      setTimeout(() => doneWaiting = true, 1000)

      // time buffer to allow some global variables to be set
      await this.delay(10)

      return axios({
        method: "GET",
        url: "/users/current"
      })
      .then(async () => {
        this.isLoggedIn = true

        // if the user is on the login page, but still has a valid token stored
        if (this.$route.name === "login") this.$router.replace("/")
      })
      .catch(() => {})
      .then(async () => {
        while (doneWaiting === false) await this.delay(10)
        this.didCheck = true
      })
    },
    onBackClick: () => {
      let { path } = router.history.current

      if (path !== "/") {
        let pathArr = path.split("/")

        if (pathArr[1] === "devices" && pathArr.length > 2) {
          pathArr = ["", "devices"]
        } else if (pathArr[1] === "images" && pathArr.length > 2) {
          pathArr = [""]
        } else {
          pathArr.pop()
        }

        router.push(pathArr.join("/") || "/")
      }
    },
    delay(t) {
      return new Promise(resolve => setTimeout(resolve, t))
    }
  }
}
</script>

<style>
@import './assets/index.css';

.main-container {
  display: flex;
}

.app-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .75rem 0;
  width: 100%;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  max-width: 450px;
  align-self: center;
  gap: 2rem;
  padding: 2rem;
}
</style>
