import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/homeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('../views/devices.vue')
  },
  {
    path: '/devices/:imei',
    name: 'device-timestamps',
    component: () => import('../views/timestamps.vue')
  },
  {
    path: '/devices/:imei/:ts',
    name: 'device-images',
    component: () => import('../views/timestamps.vue')
  },
  {
    path: '/images',
    name: 'all-timestamps',
    component: () => import('../views/all-timestamps.vue')
  },
  {
    path: '/images/:imei/:ts',
    name: 'timestamp-images',
    component: () => import('../views/all-timestamps.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
