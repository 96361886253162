import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false

const app = new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$rules = {
  smallerThan: max => value => value <= max || `Must be smaller than or equal to ${max}`,
  greaterThanZero: value => value > 0 || 'Must be greater than zero',
  integer: value => Number.isInteger(Number(value)) || 'Must be an integer',
  required: value => value !== null && value !== undefined && value !== '' || 'Field is required',
  notNegative: value => value >= 0 || "Must be positive"
}

const token = sessionStorage.getItem('logintoken')

axios.defaults.baseURL = process.env.API_SERVER
if (token) axios.defaults.headers['x-access-token'] = token

axios.interceptors.response.use(
  response => response,
  err => {
    if (err.response?.status === 401) {
      if (window.location.pathname.startsWith("/login")) return Promise.reject(err)

      delete axios.defaults.headers["x-access-token"]
      sessionStorage.removeItem('logintoken')

      sessionStorage.setItem('returnpage', window.location.pathname)

      app.$router.push({ name: "login" })

      return Promise.reject()
    }

    return Promise.reject(err)
  }
)
