<script>
import axios from 'axios'

export default {
  name: "TheHeader",
  methods: {
    userLogout() {
      sessionStorage.removeItem('logintoken')
      sessionStorage.removeItem('returnpage')
      delete axios.defaults.headers['x-access-token']

      this.$router.push({ name: "login" })
    }
  }
}
</script>

<template>
  <header id="applicationHeader">
    <p>
      <router-link to="/">Analytics Portal</router-link>
    </p>

    <v-btn
      @click="userLogout"
      color="error"
      class="allign-end"
    >
      <v-icon style="margin-right: 4px;">
        mdi-account-off
      </v-icon>
      <span style="margin-top: 2px;">
        Uitloggen
      </span>
    </v-btn>
  </header>
</template>

<style scoped>
header {
  background-color: var(--primary-color);
  padding: .7rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0 2rem;
}

header p {
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 0;
}

ul {
  display: flex;
  font-weight: 500;
  font-size: 1.15rem;
  gap: 0 1rem;
}

li:hover {
  color: var(--white-text);
  transition: color .2s;
}

p a {
  color: unset;
}

.allign-end {
  margin-left: auto;
}
</style>