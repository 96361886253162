export default [
  {
    "title": "Home",
    "icon": "mdi-home",
    "to": "/"
  },
  {
    "title": "Devices",
    "icon": "mdi-smoke-detector",
    "to": "/devices"
  },
  {
    "title": "Images",
    "icon": "mdi-image",
    "to": "/images"
  }
]