<script>
import pagesJson from '../pagelinks.js'

export default {
  name: "HomeView",
  data() {
    return {
      pages: pagesJson.slice(1)
    }
  }
}
</script>

<template>
  <v-card>
    <v-container fluid>
      <h1>Welcome to the analytics portal for Alcochem</h1>

      <p>To get started please select an item from the menu.</p>

      <v-spacer />

      <v-container fluid>
        <v-row align-content="start" class="button-container">
            <v-btn
              v-for="{ title, icon, to } in pages"
              :key="title"
              :to="to"
              color="var(--primary-color)"
              dark
              large
            >
              <v-icon>{{ icon }}</v-icon>
              {{ title }}
            </v-btn>
        </v-row>
      </v-container>
    </v-container>
  </v-card>
</template>

<style scoped>
.button-container {
  gap: 1rem;
}
</style>